<template>
  <transition
    enter-active-class="transition duration-200 ease-out"
    enter-from-class="-translate-y-5 opacity-0"
    enter-to-class="translate-y-0 opacity-100"
    leave-active-class="transition duration-150 ease-in"
    leave-from-class="translate-y-0 opacity-100"
    leave-to-class="-translate-y-5 opacity-0"
  >
    <div
      v-if="hasFlash && !dismissed"
      class="
        fixed
        top-12
        max-w-xl
        w-full
        z-30
        text-sm
        font-medium
        left-1/2
        transform
        -translate-x-1/2
      "
    >
      <div
        class="rounded-md p-4 flex items-center space-x-4"
        :class="{
          'bg-yellow-200 dark:bg-yellow-800 text-yellow-600 dark:text-yellow-200':
            flash.message,
          'bg-red-200 dark:bg-red-800 text-red-600 dark:text-red-200':
            flash.error,
          'bg-green-200 dark:bg-green-800 text-green-600 dark:text-green-200':
            flash.success,
        }"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="w-5 h-5"
          viewBox="0 0 24 24"
          fill="currentColor"
        >
          <path
            v-if="flash.message"
            d="M12 0a12 12 0 1 0 12 12A12.014 12.014 0 0 0 12 0Zm-1.5 6a1.5 1.5 0 0 1 3 0v4.8a1.5 1.5 0 0 1-3 0ZM12 19.2a2.4 2.4 0 1 1 2.4-2.4 2.4 2.4 0 0 1-2.4 2.4Z"
          />
          <path
            v-if="flash.error"
            d="M12 23.5A11.5 11.5 0 1 0 .5 12 11.51 11.51 0 0 0 12 23.5ZM7.05 9.17a1 1 0 0 1 0-1.41l.71-.71a1 1 0 0 1 1.41 0l2.65 2.65a.25.25 0 0 0 .36 0l2.65-2.65a1 1 0 0 1 1.41 0l.71.71a1 1 0 0 1 0 1.41l-2.65 2.65a.25.25 0 0 0 0 .36l2.7 2.65a1 1 0 0 1 0 1.41l-.71.71a1 1 0 0 1-1.41 0l-2.7-2.65a.25.25 0 0 0-.36 0L9.17 17a1 1 0 0 1-1.41 0l-.71-.71a1 1 0 0 1 0-1.41l2.65-2.7a.25.25 0 0 0 0-.36Z"
          />
          <path
            v-if="flash.success"
            d="M12 0a12 12 0 1 0 12 12A12.014 12.014 0 0 0 12 0Zm5.86 10.06-6.6 6.6a1.5 1.5 0 0 1-2.12 0l-3-3a1.5 1.5 0 0 1 2.12-2.12l1.94 1.938 5.54-5.539a1.5 1.5 0 0 1 2.12 2.122Z"
          />
        </svg>
        <span class="flex-1">{{
          flash.message || flash.error || flash.success
        }}</span>
        <button
          class="w-5 h-5 flex items-center justify-center"
          @click="manualDismiss"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="w-4 h-4"
            viewBox="0 0 24 24"
            fill="currentColor"
          >
            <path
              d="M14.3 12.18a.24.24 0 0 1 0-.35l9.26-9.27a1.49 1.49 0 0 0 0-2.12 1.51 1.51 0 0 0-2.12 0L12.18 9.7a.25.25 0 0 1-.36 0L2.56.44a1.51 1.51 0 0 0-2.12 0 1.49 1.49 0 0 0 0 2.12l9.26 9.27a.24.24 0 0 1 0 .35L.44 21.44a1.49 1.49 0 0 0 0 2.12 1.51 1.51 0 0 0 2.12 0l9.26-9.26a.25.25 0 0 1 .36 0l9.26 9.26a1.51 1.51 0 0 0 2.12 0 1.49 1.49 0 0 0 0-2.12Z"
            />
          </svg>
          <span class="sr-only">Dismiss</span>
        </button>
      </div>
    </div>
  </transition>
</template>
<script>
export default {
  name: "AppFlash",
  data() {
    return {
      timeout: null,
      dismissed: false,
    };
  },
  computed: {
    hasFlash() {
      return Object.values(this.flash).filter((f) => f).length > 0;
    },
    flash() {
      return this.$page.props.flash;
    },
  },
  watch: {
    flash: {
      handler() {
        if (!this.hasFlash) return;
        this.manualDismiss();
        this.dismissed = false;
        this.autoDismiss();
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    manualDismiss() {
      clearTimeout(this.timeout);
      this.timeout = null;
      this.dismissed = true;
    },
    autoDismiss() {
      this.timeout = setTimeout(this.manualDismiss, 3000);
    },
  },
};
</script>
