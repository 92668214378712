<script setup>
import { computed } from "vue";
import { Disclosure, DisclosureButton } from "@headlessui/vue";

import { MenuIcon, XIcon } from "@heroicons/vue/outline";
import { usePage } from "@inertiajs/vue3";

import AppFlash from "@Components/AppFlash";
import AppNav from "@Components/AppNav";
import AppNavMobile from "@Components/AppNavMobile";

const user = computed(() => ({
  ...usePage().props.auth,
  isAdmin: usePage().props.auth.role == "admin",
}));

const userAvatar = computed(
  () =>
    user.value.avatar ??
    `https://eu.ui-avatars.com/api/?name=${user.value.name}&background=dabb63`
);

const userNavigation = computed(() => []);

const navigation = computed(() => {
  let links = [
    {
      label: "Dashboard",
      href: "index",
      active: "index",
    },
    {
      label: "Equipment",
      href: user.value.isAdmin
        ? "admin.equipment.index"
        : "staff.equipment.index",
      active: user.value.isAdmin ? "admin.equipment.*" : "staff.equipment.*",
    },
  ];

  if (user.value.isAdmin) {
    links = [
      ...links,
      {
        label: "Jobs",
        href: "admin.jobs.index",
        active: "admin.jobs.*",
      },
      {
        label: "Staff",
        href: "admin.users.index",
        active: "admin.users.*",
      },
    ];
  }

  return links;
});
</script>

<template>
  <div>
    <Disclosure v-slot="{ open }" as="nav" class="bg-blue-900">
      <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div class="flex items-center justify-between h-16">
          <div class="flex items-center">
            <div class="flex-shrink-0 text-white">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 1438 211"
                class="w-28 border-t-2 border-b-2 border-white py-2"
              >
                <path
                  fill="currentColor"
                  fill-rule="nonzero"
                  d="M108.983 211c31.938 0 56.312-10.648 75.923-33.625C205.078 153.556 209 130.859 209 103.679H107.582v36.427h58.273c-10.926 23.258-28.576 34.186-55.191 34.186-40.344 0-72.002-30.543-72.002-69.493 0-37.268 29.137-68.091 69.48-68.091 23.814 0 42.585 9.527 59.674 27.46l26.896-26.62C173.139 14.011 147.645 0 107.862 0 44.265 0 0 47.636 0 104.239 0 161.402 42.024 211 108.983 211ZM338 206v-36.393h-55.673V5H244v201h94Zm87.395 0v-87.063L484 5h-40.744l-36.837 71.946L369.023 5H328l59.163 113.937V206h38.232Zm112.75 0L560.21 78.905 602.382 206h33.515l42.731-127.095L700.134 206H737L703.206 5H666.34l-46.921 140.252L573.057 5h-37.425L501 206h37.146Zm267.266 0v-85.103c21.028 0 38.972.28 56.075-10.078C878.308 100.46 887 84.504 887 63.229c0-17.077-6.449-32.474-16.262-41.992C858.121 8.919 839.898 5 813.822 5H767v201h38.411Zm18.525-122H806V43h16.255C840.47 43 850 50.252 850 63.64 850 77.305 841.312 84 823.936 84Zm154.157 122V42.513H1015V5H904v37.513h35.788V206h38.305Zm159.106 5C1194.835 211 1241 165.325 1241 106.2 1241 44.835 1192.037 0 1136.64 0 1079.564 0 1032 45.675 1032 105.36c0 58.564 45.605 105.64 105.2 105.64Zm-.779-37c-35.592 0-66.42-27.065-66.42-67.802C1070 64.902 1099.707 37 1135.86 37c38.395 0 66.14 32.088 66.14 68.919 0 38.226-29.147 68.081-65.58 68.081Zm178.873 32V74.146L1401.104 206H1438V5h-38.293v132.134L1313.617 5H1277v201h38.293Z"
                />
              </svg>
            </div>
            <div class="hidden md:block">
              <AppNav :navigation="navigation" />
            </div>
          </div>
          <div class="hidden md:block">
            <div class="ml-4 flex items-center md:ml-6">
              <!-- Profile dropdown -->
              <!-- <Menu
                as="div"
                class="ml-3 relative"
              > -->
              <div>
                <!-- <MenuButton
                    class="
                      max-w-xs
                      bg-blue-800
                      rounded-full
                      flex
                      items-center
                      text-sm text-white
                      focus:outline-none
                      focus:ring-2
                      focus:ring-offset-2
                      focus:ring-offset-blue-800
                      focus:ring-white
                    "
                  > -->
                <!-- <span class="sr-only">Open user menu</span> -->
                <img class="h-8 w-8 rounded-full" :src="userAvatar" alt="" />
                <!-- </MenuButton> -->
              </div>
              <!-- <transition
                  enter-active-class="transition ease-out duration-100"
                  enter-from-class="transform opacity-0 scale-95"
                  enter-to-class="transform opacity-100 scale-100"
                  leave-active-class="transition ease-in duration-75"
                  leave-from-class="transform opacity-100 scale-100"
                  leave-to-class="transform opacity-0 scale-95"
                >
                  <MenuItems
                    class="
                      origin-top-right
                      absolute
                      right-0
                      mt-2
                      w-48
                      rounded-md
                      shadow-lg
                      py-1
                      bg-white
                      ring-1 ring-black ring-opacity-5
                      focus:outline-none
                    "
                  >
                    <MenuItem
                      v-for="item in navigation"
                      :key="item.href"
                    >
                      <Link
                        :href="item.href"
                        :class="[
                          item.active ? 'bg-blue-100' : '',
                          'block px-4 py-2 text-sm text-gray-700',
                        ]"
                      >
                        {{ item.label }}
                      </Link>
                    </MenuItem>
                  </MenuItems>
                </transition>
              </Menu> -->
            </div>
          </div>
          <div class="-mr-2 flex md:hidden">
            <!-- Mobile menu button -->
            <DisclosureButton
              class="bg-blue-800 inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-blue-800 focus:ring-white"
            >
              <span class="sr-only">Open main menu</span>
              <MenuIcon v-if="!open" class="block h-6 w-6" aria-hidden="true" />
              <XIcon v-else class="block h-6 w-6" aria-hidden="true" />
            </DisclosureButton>
          </div>
        </div>
      </div>

      <AppNavMobile
        :navigation="navigation"
        :user-navigation="userNavigation"
      />
    </Disclosure>

    <main>
      <slot />
    </main>
    <AppFlash />
  </div>
</template>
