<template>
  <DisclosurePanel
    v-slot="{ close }"
    class="md:hidden"
  >
    <div class="px-2 pt-2 pb-3 space-y-1 sm:px-3">
      <Link
        v-for="item in navigation"
        :key="item.href"
        :href="route(item.href)"
        :class="[
          route().current(item.active)
            ? 'bg-blue-900 text-white'
            : 'text-gray-300 hover:bg-blue-700 hover:text-white',
          'block px-3 py-2 rounded-md text-base font-medium',
        ]"
        :aria-current="route().current(item.active) ? 'page' : undefined"
        @click="close"
      >
        {{ item.label }}
      </Link>
    </div>
    <div class="pt-4 pb-3 border-t border-gray-700">
      <div class="mt-3 px-2 space-y-1">
        <Link
          v-for="item in userNavigation"
          :key="item.href"
          :href="route(item.href)"
          class="
            block
            px-3
            py-2
            rounded-md
            text-base
            font-medium
            text-gray-400
            hover:text-white
            hover:bg-blue-700
          "
          @click="close"
        >
          {{ item.label }}
        </Link>
      </div>
    </div>
  </DisclosurePanel>
</template>

<script>
import { DisclosurePanel } from '@headlessui/vue'

export default {
  name: "AppNavMobile",
  components: {
    DisclosurePanel
  },
  props: {
    navigation: {
      type: Array,
      default: () => []
    },
    userNavigation: {
      type: Array,
      default: () => []
    }
  },
}
</script>
