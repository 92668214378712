import { createApp, h } from "vue";
import { ZiggyVue } from "ziggy";
import { createInertiaApp, Head, Link } from "@inertiajs/vue3";
import autosize from "autosize";

import AuthLayout from "./Pages/Auth/Layout";
import Layout from "./Components/Layout";

window._mapboxAccessToken =
  "pk.eyJ1IjoicGRjLWRpZ2l0YWwiLCJhIjoiY2s5aTc3ZDEwMDBwaTNkcXNtc2ZxZnNjZCJ9.YL_jAO9Z_qBbvyJWBvIzpQ";

createInertiaApp({
  title: (title) =>
    title ? `${title} - Glympton Construction` : "Glympton Construction",
  resolve: (name) =>
    import(`./Pages/${name}`).then(({ default: page }) => {
      if (page.layout) return page;
      if (name.startsWith("Auth/")) {
        page.layout = AuthLayout;
      } else {
        page.layout = Layout;
      }
      return page;
    }),
  setup({ el, App, props, plugin }) {
    createApp({
      render: () => h(App, props),
    })
      .component("Link", Link)
      .component("Head", Head)
      .directive("autosize", {
        created(el) {
          autosize(el);
        },
        mounted(el) {
          autosize.update(el);
        },
        beforeUnmount(el) {
          autosize.destroy(el);
        },
      })
      .use(plugin)
      .use(ZiggyVue, Ziggy)
      .mount(el);
  },
  progress: {
    color: "rgb(37, 99, 235)",
  },
});
