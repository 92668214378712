<template>
  <nav class="ml-10 flex items-baseline space-x-4">
    <Link
      v-for="item in navigation"
      :key="item.href"
      :href="route(item.href)"
      :class="[
        route().current(item.active)
          ? 'bg-black bg-opacity-30 text-white'
          : 'text-blue-200 hover:bg-black hover:bg-opacity-20 hover:text-white',
        'px-3 py-2 rounded-md text-sm font-medium',
      ]"
      :aria-current="route().current(item.active) ? 'page' : undefined"
    >
      {{ item.label }}
    </Link>
  </nav>
</template>

<script>
export default {
  name: "AppNav",
  props: {
    navigation: {
      type: Array,
      default: () => []
    }
  },
  created() {
    document.addEventListener('inertia:success', this.$forceUpdate)
  }
}
</script>
